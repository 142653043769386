<template>
  <div class="tourism-container mt-5">
    <h3 class="font-weight-bolder text-colorBlue pt-5 text-center">
      Explore <span class="text-colorGreen">Tourism Destinations</span>
    </h3>
    <div class="tourism-wrapper">
      <button class="nav-button left-button" @click="prev">
        <feather-icon
          icon="ChevronsLeftIcon"
          class="arrow-icon text-colorGreen"
        />
      </button>
      <div class="tourism-scroll">
        <b-row class="p-0 mt-5 no-gutters">
          <b-col
            v-for="(tourism, index) in visibleTourisms"
            :key="index"
            class="p-0 mb-1 mb-sm-0 gap-class"
          >
            <a href="/tourism">
              <div class="image-container p-0">
                <img
                  :src="tourism.images[0].image"
                  class="img-fluid rounded p-0"
                  alt="image"
                />
                <div class="overlay">
                  <h3
                    class="overlay-text text-center font-weight-bolder text-light"
                  >
                    {{ tourism.name }}
                  </h3>
                </div>
              </div>
            </a>
          </b-col>
        </b-row>
      </div>
      <button class="nav-button right-button" @click="next">
        <feather-icon
          icon="ChevronsRightIcon"
          class="arrow-icon text-colorGreen"
        />
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      tourisms: [],
      startIndex: 0,
      visibleCount: 3,
    };
  },
  computed: {
    visibleTourisms() {
      return this.tourisms.slice(
        this.startIndex,
        this.startIndex + this.visibleCount
      );
    },
  },
  async mounted() {
    try {
      const tourismRes = await this.getTouristattractionPublic({});
      if (tourismRes.status === 200) {
        this.tourisms = tourismRes.data;
      }
    } catch (error) {}
  },
  methods: {
    ...mapActions({
      getTouristattractionPublic: "appData/getTouristattractionPublic",
    }),
    next() {
      if (this.startIndex + this.visibleCount < this.tourisms.length) {
        this.startIndex += 1;
      }
    },
    prev() {
      if (this.startIndex > 0) {
        this.startIndex -= 1;
      }
    },
  },
};
</script>

<style scoped>
.tourism-container {
  padding: 0 20px;
}

.tourism-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.tourism-scroll {
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
}

.image-container {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 500px;
  height: 300px;
}

.image-container img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(63, 172, 73, 0.5);
  opacity: 0;
  transition: opacity 0.9s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container:hover .overlay {
  opacity: 1;
}

.nav-button {
  border: none;
  background-color: transparent;
  font-size: 24px;
  cursor: pointer;
  z-index: 10;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.left-button {
  left: 70px;
}

.right-button {
  right: 70px;
}

.arrow-icon {
  width: 24px;
  height: 24px;
}

.gap-class {
  margin-right: 15px;
}

.gap-class:last-child {
  margin-right: 0;
}
</style>
