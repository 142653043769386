<template>
  <div class="mt-5 my-bg">
    <b-container class="py-2">
      <h3 class="font-weight-bolder text-colorBlue pt-5">
        Procedure Pricing:
        <span class="text-colorGreen"> Transparency in Healthcare Costs</span>
      </h3>
      <b-row class="px-2 py-5 mt-3 d-flex justify-content-between">
        <b-col md="" class="bg-colorGrey rounded mr-0 mr-md-1 mb-5 mb-md-0">
          <div class="text-center pt-5 mt-2">
            <img
              src="@/assets/images/icons/pricing/knee replacement.svg"
              img-fluid
              width="30%"
              alt="image"
            />
          </div>
          <div class="text-center pt-1">
            <b-button
              rounded
              variant="colorBlue"
              size="sm"
              class="font-weight-bolder px-2"
              >surgery
            </b-button>
          </div>
          <div class="text-center pt-1">
            <h6 class="font-weight-bolder text-colorBlue">
              Total Knee Replacement
            </h6>
          </div>
          <div class="px-4 py-1">
            <hr class="bg-colorBlue" style="height: 1px" />
          </div>
          <div class="px-1">
            <h6 class="font-weight-bolder text-colorText">
              Duration:
              <span class="font-weight-normal text-colorText">5 Days</span>
            </h6>
          </div>
          <div class="px-4 py-1">
            <hr class="bg-colorBlue" style="height: 1px" />
          </div>
          <div class="px-1">
            <h6 class="font-weight-bolder text-colorText">
              Hospital:
              <span class="font-weight-normal text-colorText">
                Combined Hospital, Rawalpindi</span
              >
            </h6>
          </div>
          <div class="px-4 py-1">
            <hr class="bg-colorBlue" style="height: 1px" />
          </div>
          <div class="px-1">
            <h6 class="font-weight-bolder text-colorText">
              Doctor:
              <span class="font-weight-normal text-colorText">
                Dr. Muhammad Sohail Amin</span
              >
            </h6>
          </div>
          <div class="px-4 py-1">
            <hr class="bg-colorBlue" style="height: 1px" />
          </div>
          <div class="px-1">
            <h6 class="font-weight-bolder text-colorText">
              Location:
              <span class="font-weight-normal text-colorText">
                Saddar, Rawalpindi, Pakistan</span
              >
            </h6>
          </div>
          <div class="px-4 py-1">
            <hr class="bg-colorBlue" style="height: 1px" />
          </div>
          <div class="text-center py-2">
            <b-button
              rounded
              variant="colorGreen"
              size="md"
              class="font-weight-bolder px-5"
              >Select
            </b-button>
          </div>
          <div
            class="bg-colorBlue rounded py-2 position-relative mx-2"
            style="top: -94%"
          >
            <p class="font-weight-bolder text-light text-center p-0 m-0">
              Starting from
            </p>
            <h1 class="font-weight-bolder text-light text-center p-0 m-0">
              2.5K
            </h1>
          </div>
        </b-col>
        <b-col md="" class="bg-colorGrey rounded mr-0 mr-md-1 mb-5 mb-md-0">
          <div class="text-center pt-5 mt-2">
            <img
              src="@/assets/images/icons/pricing/hair transplant.svg"
              img-fluid
              alt="image"
              width="30%"
            />
          </div>
          <div class="text-center pt-1">
            <b-button
              rounded
              variant="colorBlue"
              size="sm"
              class="font-weight-bolder px-2"
              >surgery
            </b-button>
          </div>
          <div class="text-center pt-1">
            <h6 class="font-weight-bolder text-colorBlue">Hair Transplant</h6>
          </div>
          <div class="px-4 py-1">
            <hr class="bg-colorBlue" style="height: 1px" />
          </div>
          <div class="px-1">
            <h6 class="font-weight-bolder text-colorText">
              Duration:
              <span class="font-weight-normal text-colorText">1 Days</span>
            </h6>
          </div>
          <div class="px-4 py-1">
            <hr class="bg-colorBlue" style="height: 1px" />
          </div>
          <div class="px-1">
            <h6 class="font-weight-bolder text-colorText">
              Hospital:
              <span class="font-weight-normal text-colorText">
                Combined Hospital, Rawalpindi</span
              >
            </h6>
          </div>
          <div class="px-4 py-1">
            <hr class="bg-colorBlue" style="height: 1px" />
          </div>
          <div class="px-1">
            <h6 class="font-weight-bolder text-colorText">
              Doctor:
              <span class="font-weight-normal text-colorText">
                Dr. Qamar ud Din Khan</span
              >
            </h6>
          </div>
          <div class="px-4 py-1">
            <hr class="bg-colorBlue" style="height: 1px" />
          </div>
          <div class="px-1">
            <h6 class="font-weight-bolder text-colorText">
              Location:
              <span class="font-weight-normal text-colorText">
                Saddar, Rawalpindi, Pakistan</span
              >
            </h6>
          </div>
          <div class="px-4 py-1">
            <hr class="bg-colorBlue" style="height: 1px" />
          </div>
          <div class="text-center py-2">
            <b-button
              rounded
              variant="colorGreen"
              size="md"
              class="font-weight-bolder px-5"
              >Select
            </b-button>
          </div>
          <div
            class="bg-colorBlue rounded py-2 position-relative mx-2"
            style="top: -94%"
          >
            <p class="font-weight-bolder text-light text-center p-0 m-0">
              Starting from
            </p>
            <h2 class="font-weight-bolder text-light text-center p-0 m-0">
              7K
            </h2>
          </div>
        </b-col>
        <b-col md="" class="bg-colorGrey rounded mr-0 mr-md-1 mb-5 mb-md-0">
          <div class="text-center pt-5 mt-2">
            <img
              src="@/assets/images/icons/pricing/kidney transplant.svg"
              img-fluid
              alt="image"
              width="30%"
            />
          </div>
          <div class="text-center pt-1">
            <b-button
              rounded
              variant="colorBlue"
              size="sm"
              class="font-weight-bolder px-2"
              >surgery
            </b-button>
          </div>
          <div class="text-center pt-1">
            <h6 class="font-weight-bolder text-colorBlue">Kidney Transplant</h6>
          </div>
          <div class="px-4 py-1">
            <hr class="bg-colorBlue" style="height: 1px" />
          </div>
          <div class="px-1">
            <h6 class="font-weight-bolder text-colorText">
              Duration:
              <span class="font-weight-normal text-colorText">2 Days</span>
            </h6>
          </div>
          <div class="px-4 py-1">
            <hr class="bg-colorBlue" style="height: 1px" />
          </div>
          <div class="px-1">
            <h6 class="font-weight-bolder text-colorText">
              Hospital:
              <span class="font-weight-normal text-colorText">
                Combined Hospital, Rawalpindi</span
              >
            </h6>
          </div>
          <div class="px-4 py-1">
            <hr class="bg-colorBlue" style="height: 1px" />
          </div>
          <div class="px-1">
            <h6 class="font-weight-bolder text-colorText">
              Doctor:
              <span class="font-weight-normal text-colorText">
                Dr. Muhammad Shan Malik</span
              >
            </h6>
          </div>
          <div class="px-4 py-1">
            <hr class="bg-colorBlue" style="height: 1px" />
          </div>
          <div class="px-1">
            <h6 class="font-weight-bolder text-colorText">
              Location:
              <span class="font-weight-normal text-colorText">
                Saddar, Rawalpindi, Pakistan</span
              >
            </h6>
          </div>
          <div class="px-4 py-1">
            <hr class="bg-colorBlue" style="height: 1px" />
          </div>
          <div class="text-center py-2">
            <b-button
              rounded
              variant="colorGreen"
              size="md"
              class="font-weight-bolder px-5"
              >Select
            </b-button>
          </div>
          <div
            class="bg-colorBlue rounded py-2 position-relative mx-2"
            style="top: -94%"
          >
            <p class="font-weight-bolder text-light text-center p-0 m-0">
              Starting from
            </p>
            <h2 class="font-weight-bolder text-light text-center p-0 m-0">
              1.5K
            </h2>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.my-bg {
  background: url("~@/assets/images/images/testimonials-overlay.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
</style>
