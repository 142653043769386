<template>
  <div>
    <div>
      <b-container class="pt-5 px-5">
        <div class="my-bg mt-3">
          <b-container>
            <h2 class="font-weight-bolder text-colorBlue text-center mb-3">
              Our Services
            </h2>
            <b-row class="d-flex align-items-center">
              <b-col md="6">
                <div class="pt-5">
                  <h3 class="font-weight-bolder text-colorBlue pb-1">
                    Dedicated, One Stop Seamless Assistance
                  </h3>
                  <p class="font-weight-bold text-colorText">
                    Your health, your adventure, your way. We tailor every
                    itinerary to blend top medical care with stunning
                    destinations, overcoming language barriers and cultural
                    differences. Our team, fluent in your language, handles
                    everything from medical appointments to travel logistics,
                    ensuring a seamless and enriching experience.
                  </p>
                </div>
              </b-col>
              <b-col md="6" class="d-none d-lg-block">
                <b-row>
                  <b-col class="my-col">
                    <router-link
                      :to="{ name: 'VisaAssistance' }"
                      class="logo-container"
                    >
                      <img
                        src="@/assets/images/images/services/visa assistance.webp"
                        class="mt-1 icon-default"
                        width="120px"
                        alt="image"
                      />
                      <img
                        src="@/assets/images/images/services/visa assistance green.webp"
                        class="mt-1 icon-hover"
                        width="120px"
                        alt="image"
                      />
                    </router-link>
                  </b-col>
                  <b-col class="my-col">
                    <router-link :to="{ name: 'Hotel' }" class="logo-container">
                      <img
                        src="@/assets/images/images/services/hotels.webp"
                        class="mt-1 icon-default"
                        width="120px"
                        alt="image"
                      />
                      <img
                        src="@/assets/images/images/services/hotels green.webp"
                        class="mt-1 icon-hover"
                        width="120px"
                        alt="image"
                      />
                    </router-link>
                  </b-col>
                  <b-col class="my-col">
                    <router-link
                      :to="{ name: 'CarRental' }"
                      class="logo-container"
                    >
                      <img
                        src="@/assets/images/images/services/transport service.webp"
                        class="mt-1 icon-default"
                        width="120px"
                        alt="image"
                      />
                      <img
                        src="@/assets/images/images/services/transport service green.webp"
                        class="mt-1 icon-hover"
                        width="120px"
                        alt="image"
                      />
                    </router-link>
                  </b-col>
                  <b-col class="my-col">
                    <router-link
                      :to="{ name: 'Interpreter' }"
                      class="logo-container"
                    >
                      <img
                        src="@/assets/images/images/services/interpreter service.webp"
                        class="mt-1 icon-default"
                        width="120px"
                        alt="image"
                      />
                      <img
                        src="@/assets/images/images/services/interpreter service green.webp"
                        class="mt-1 icon-hover"
                        width="120px"
                        alt="image"
                      />
                    </router-link>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col> </b-col>

                  <b-col class="my-col">
                    <router-link
                      :to="{ name: 'Tourism' }"
                      class="logo-container"
                    >
                      <img
                        src="@/assets/images/images/services/tourism.webp"
                        class="mt-1 icon-default"
                        width="120px"
                        alt="image"
                      />
                      <img
                        src="@/assets/images/images/services/tourism green.webp"
                        class="mt-1 icon-hover"
                        width="120px"
                        alt="image"
                      />
                    </router-link>
                  </b-col>
                  <b-col class="my-col">
                    <router-link
                      :to="{ name: 'Insurance' }"
                      class="logo-container"
                    >
                      <img
                        src="@/assets/images/images/services/insurance.webp"
                        class="mt-1 icon-default"
                        width="120px"
                        alt="image"
                      />
                      <img
                        src="@/assets/images/images/services/insurance green.webp"
                        class="mt-1 icon-hover"
                        width="120px"
                        alt="image"
                      />
                    </router-link>
                  </b-col>
                  <b-col> </b-col>
                  <!-- <b-col class="my-col">
                    <b-link :to="{ name: 'SafeTravel' }" class="logo-container">
                      <img
                        src="@/assets/images/images/services/safe travel.png"
                        class="mt-1 icon-default"
                        width="120px"
                      />
                      <img
                        src="@/assets/images/images/services/safe travel green.png"
                        class="mt-1 icon-hover"
                        width="120px"
                      />
                    </b-link>
                  </b-col> -->
                </b-row>
              </b-col>
            </b-row></b-container
          >
        </div>
      </b-container>
      <b-container class="mt-4">
        <div class="pt-50">
          <h2 class="font-weight-bolder text-colorBlue text-center mt-5">
            Enjoy the Luxury of a Medical City
          </h2>
          <h3
            class="font-weight-bold py-2 text-colorBlue text-center font-weight-bold"
          >
            One City, Infinite Possibilities: Your Comprehensive Healthcare
            Journey Awaits. Discover the power of integrated care with The
            MedAsk Tours Advantage.
          </h3>
        </div>
        <b-row>
          <b-col md="12">
            <h2 class="font-weight-bolder text-colorBlue">Hospitals</h2>
            <b-row
              class="d-flex justify-content-md-between justify-content-center align-items-center pt-2"
            >
              <b-col md="2" class="w-auto">
                <a href="/hospital/dentistry/4">
                  <img
                    src="@/assets/images/images/department/Dentistry.webp"
                    class="img-fluid"
                    style="border-radius: 20px"
                    alt="image"
                  />

                  <h6
                    class="text-center font-weight-bolder text-colorText py-2"
                  >
                    Dentistry
                  </h6>
                </a>
              </b-col>
              <b-col md="2" class="w-auto">
                <a href="/hospital/cardiology/2">
                  <img
                    src="@/assets/images/images/department/Cardiology.webp"
                    class="img-fluid"
                    style="border-radius: 20px"
                    alt="image"
                  />

                  <h6
                    class="text-center font-weight-bolder text-colorText py-2"
                  >
                    Cardiology
                  </h6>
                </a>
              </b-col>
              <b-col md="2" class="w-auto">
                <a href="/hospital/ophthalmology/5">
                  <img
                    src="@/assets/images/images/department/ophthalmology.webp"
                    class="img-fluid pr-0 pr-sm-0"
                    style="border-radius: 20px"
                    alt="image"
                  />

                  <h6
                    class="text-center font-weight-bolder text-colorText py-2"
                  >
                    Ophthalmology
                  </h6>
                </a>
              </b-col>
              <b-col md="2" class="w-auto">
                <a href="/hospital/urology/6">
                  <img
                    src="@/assets/images/images/department/Urology.webp"
                    class="img-fluid pr-0 pr-sm-0"
                    style="border-radius: 20px"
                    alt="image"
                  />

                  <h6
                    class="text-center font-weight-bolder text-colorText py-2"
                  >
                    Urology
                  </h6>
                </a>
              </b-col>
              <b-col md="2" class="w-auto">
                <a href="/hospital/mentalhealth/9">
                  <img
                    src="@/assets/images/images/department/Mental Health.webp"
                    class="img-fluid pr-0 pr-sm-0"
                    style="border-radius: 20px"
                    alt="image"
                  />

                  <h6
                    class="text-center font-weight-bolder text-colorText py-2"
                  >
                    Mental Health
                  </h6>
                </a>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="">
          <b-col md="12">
            <b-row
              class="d-md-flex d-sm-flex justify-content-md-between justify-content-center align-items-center pt-2"
            >
              <b-col md="2" class="w-auto">
                <a href="/hospital/rehabilitationmedicine/1">
                  <img
                    src="@/assets/images/images/department/Rehabilitation Medicine.webp"
                    class="img-fluid"
                    style="border-radius: 20px"
                    alt="image"
                  />

                  <h6
                    class="text-center font-weight-bolder text-colorText py-2"
                  >
                    Rehabilitation Medicine
                  </h6>
                </a>
              </b-col>
              <b-col md="2" class="w-auto">
                <a href="/hospital/bonemarrowtransplant/3">
                  <img
                    src="@/assets/images/images/department/Bone marrow Transplant.webp"
                    class="img-fluid"
                    style="border-radius: 20px"
                    alt="image"
                  />

                  <h6
                    class="text-center font-weight-bolder text-colorText py-2"
                  >
                    Bone Marrow Transplant
                  </h6>
                </a>
              </b-col>
              <b-col md="2" class="w-auto">
                <a href="/hospital/transfusion/11">
                  <img
                    src="@/assets/images/images/department/Transfusion.webp"
                    class="img-fluid pr-0 pr-sm-0"
                    style="border-radius: 20px"
                    alt="image"
                  />

                  <h6
                    class="text-center font-weight-bolder text-colorText py-2"
                  >
                    Transfusion
                  </h6>
                </a>
              </b-col>
              <b-col md="2" class="w-auto">
                <a href="/hospital/radiologyimaging/12">
                  <img
                    src="@/assets/images/images/department/Radiology Imaging.webp"
                    class="img-fluid pr-0 pr-sm-0"
                    style="border-radius: 20px"
                    alt="image"
                  />

                  <h6
                    class="text-center font-weight-bolder text-colorText py-2"
                  >
                    Radiology & Imaging
                  </h6>
                </a>
              </b-col>
              <b-col md="2" class="w-auto">
                <a href="/hospital/pathology/10">
                  <img
                    src="@/assets/images/images/department/Pathology.webp"
                    class="img-fluid pr-0 pr-sm-0"
                    style="border-radius: 20px"
                    alt="image"
                  />

                  <h6
                    class="text-center font-weight-bolder text-colorText py-2"
                  >
                    Pathology
                  </h6>
                </a>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="pb-5">
      <div
        class="bg-colorBlue mt-5 py-5 d-flex align-items-center justify-content-end"
      >
        <b-container class="">
          <b-row class="d-flex align-items-center justify-content-center">
            <b-col
              md="4"
              class="d-flex align-items-center justify-content-center mb-1 mb-sm-0"
            >
              <img
                src="@/assets/images/icons/patient served.svg"
                class="mr-50"
                width="80px"
                alt="image"
              />
              <div class="ml-2">
                <h2 class="text-white font-weight-bolder mb-0">32,000 +</h2>
                <span class="text-white font-weight-bolder">Patients </span>
              </div>
            </b-col>
            <b-col
              md="4"
              class="d-flex align-items-center justify-content-center mb-1 mb-sm-0"
            >
              <img
                src="@/assets/images/icons/hospitals.svg"
                class="mr-50"
                width="80px"
                alt="image"
              />
              <div class="ml-2">
                <h2 class="text-white font-weight-bolder mb-0">
                  12 +<!-- {{ count_hospital }} -->
                </h2>
                <span class="text-white font-weight-bolder">Hospitals</span>
              </div>
            </b-col>
            <b-col
              md="4"
              class="d-flex align-items-center justify-content-center"
            >
              <img
                src="@/assets/images/icons/countries.svg"
                class="mr-50"
                width="80px"
                alt="image"
              />
              <div class="d-flex align-item-start">
                <div class="ml-2">
                  <h2 class="text-white font-weight-bolder mb-0">30 +</h2>
                  <span class="text-white font-weight-bolder">Countries</span>
                </div>
                <!-- <div>
                  <feather-icon
                    icon="PlusIcon"
                    size="24"
                    class="mr-50 text-light"
                  />
                </div> -->
              </div>
            </b-col>
          </b-row></b-container
        >
      </div>
    </div>

    <b-container class="pt-5">
      <h2 class="font-weight-bolder text-center text-colorBlue mt-5 mb-3">
        Patient's Testimonials
      </h2>

      <p class="text-colorText font-weight-bold">
        At MedAsk Tours, we pride ourselves on providing exceptional service to
        our clients, ensuring that every aspect of their medical journey is
        smooth, secure, and stress-free. Our comprehensive approach covers
        everything from visa assistance and transportation to personal security
        and interpreter services, allowing our clients to focus solely on their
        health and well-being.
      </p>

      <b-row class="mt-3 d-flex align-items-center">
        <b-col md="6" v-if="currentTestimonial">
          <h3 class="font-weight-bolder">{{ currentTestimonial.full_name }}</h3>
          <p class="font-weight-bolder p-0">
            {{ currentTestimonial.country }}
          </p>
          <div class="d-flex mb-2">
            <feather-icon
              v-for="n in 5"
              :key="n"
              icon="StarIcon"
              fill="gold"
              stroke="none"
              size="24"
              :style="{
                fill: n <= currentTestimonial.rating ? 'gold' : 'lightgray',
              }"
              class="mr-50"
            />
          </div>
          <p
            class="mb-3 text-colorText font-weight-bold"
            style="text-align: justify; display: block"
          >
            {{ currentTestimonial.review }}
          </p>

          <div class="d-flex justify-content-center my-3">
            <b-button
              @click="prevTestimonial"
              class="bg-primary mr-50"
              pill
              size="sm"
              variant="colorGreen"
            >
              <feather-icon icon="ChevronLeftIcon" size="24" />
            </b-button>
            <b-button
              @click="nextTestimonial"
              pill
              size="sm"
              variant="colorGreen"
            >
              <feather-icon icon="ChevronRightIcon" size="24" />
            </b-button>
          </div>
          <div class="d-flex align-items-center">
            <div class="mr-1">
              <b-avatar size="45" style="z-index: 1">
                <img
                  class="img-fluid"
                  src="@/assets/images/home/user.webp"
                  alt="Doctor Image"
                />
              </b-avatar>
              <b-avatar size="45" style="z-index: 2; margin-left: -20px">
                <img
                  class="img-fluid"
                  src="@/assets/images/home/user1.webp"
                  alt="Doctor Image"
                  style=""
                />
              </b-avatar>
              <b-avatar size="45" style="z-index: 3; margin-left: -20px">
                <img
                  class="img-fluid"
                  src="@/assets/images/home/user2.webp"
                  alt="Doctor Image"
                  style=""
                />
              </b-avatar>
              <b-avatar size="45" style="z-index: 4; margin-left: -20px">
                <img
                  class="img-fluid"
                  src="@/assets/images/home/user3.webp"
                  alt="Doctor Image"
                  style=""
                />
              </b-avatar>
            </div>
            <p class="m-0 text-colorGreen font-weight-bolder">
              1K+ Satisfied Patients
            </p>
          </div>
        </b-col>
        <b-col md="6" class="text-center" v-if="currentTestimonial">
          <img
            :src="currentTestimonial.image"
            alt="Doctor Image"
            height="340px"
            class="mt-2 mt-md-0"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import util from "@/util.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  mixins: [util],
  components: {
    ToastificationContent,
  },
  data() {
    return {
      count_hospital: 0,
      testimonials: [],
      currentIndex: 0,
      intervalId: null,
    };
  },

  methods: {
    ...mapActions({
      getTestimonialPublic: "appData/getTestimonialPublic",
    }),
    nextTestimonial() {
      this.currentIndex = (this.currentIndex + 1) % this.testimonials.length;
    },
    prevTestimonial() {
      this.currentIndex =
        (this.currentIndex - 1 + this.testimonials.length) %
        this.testimonials.length;
    },
    startAutoScroll() {
      this.intervalId = setInterval(this.nextTestimonial, 5000); // Change every 5 seconds
    },
    stopAutoScroll() {
      clearInterval(this.intervalId);
    },
  },
  async mounted() {
    try {
      const res = await this.getTestimonialPublic({});
      if (res.status === 200) {
        this.testimonials = res.data;
      }
    } catch (error) {
      this.displayError(error);
    }
    this.startAutoScroll();
  },
  beforeDestroy() {
    this.stopAutoScroll();
  },
  computed: {
    currentTestimonial() {
      return this.testimonials[this.currentIndex];
    },
    ...mapState({
      cart: (state) => state.appData.cart,
    }),
  },
};
</script>

<style scoped>
.my-col {
  transition: 0.5s ease;
}
.my-col:hover {
  transform: translateY(-8px);
}
.logo-container {
  display: block;
  position: relative; /* Ensure absolute positioning works for child elements */
}

.icon-default,
.icon-hover {
  top: 0;
  left: 0;
}

.icon-hover {
  display: none; /* Hide hover image by default */
}

.logo-container:hover .icon-default {
  display: none; /* Hide default image on hover */
}

.logo-container:hover .icon-hover {
  display: block; /* Show hover image on hover */
}
</style>
