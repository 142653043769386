<template>
  <div class="mt-5">
    <b-container class="">
      <h2 class="text-center mb-1 font-weight-bolder text-colorBlue">
        Our Process
      </h2>

      <b-row class="d-flex justify-content-center">
        <b-col md="2">
          <div class="text-center">
            <b-img
              src="@/assets/images/process/1.webp"
              alt="Image"
              bottom
              class="img-fluid"
            ></b-img>
          </div>

          <div class="text-left mt-2 text-center">
            <p class="font-weight-bold text-colorText">
              Fill out our form,request a quote, and select your preferred
              doctor.
            </p>
          </div>
        </b-col>
        <b-col md="2">
          <div class="text-center">
            <b-img
              src="@/assets/images/process/3.webp"
              height="260"
              alt="Image"
              bottom
              class="img-fluid"
            ></b-img>
          </div>

          <div class="text-left mt-2 text-center">
            <p class="font-weight-bold text-colorText">
              We'll connect you with the doctor or hospital to finalize your
              plan.
            </p>
          </div>
        </b-col>
        <b-col md="2">
          <div class="text-center">
            <b-img
              src="@/assets/images/process/2.webp"
              height="260"
              alt="Image"
              bottom
              class="img-fluid"
            ></b-img>
          </div>

          <div class="text-left mt-2 text-center">
            <p class="font-weight-bold text-colorText">
              Upon arrival, meet with your doctor or hospital and enjoy our
              additional services, including tours and local visits.
            </p>
          </div>
        </b-col>
        <b-col md="2">
          <div class="text-center">
            <b-img
              src="@/assets/images/process/4.webp"
              height="260"
              alt="Image"
              bottom
              class="img-fluid"
            ></b-img>
          </div>

          <div class="text-left mt-2 text-center">
            <p class="font-weight-bold text-colorText">
              Receive follow-up care during your visit, or after returning to
              your home country.
            </p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {};
</script>

<style scoped></style>
